import Vue, { ref, watch } from 'vue'

export const useSelectableTable = table => {
  const rowsSelected = ref([])

  const isAllRowsSelected = ref(false)

  const isSomeRowsSelected = ref(false)

  const syncSelectAllCheckbox = () => {
    const listTable = table.value

    const localItemsCount = listTable ? listTable.localItems.length : 0

    const checked = localItemsCount && (listTable.selectedRows.filter(i => !!i).length === listTable.localItems.length)
    const intermediate = !!listTable && listTable.selectableHasSelection && !checked

    if (checked) {
      isSomeRowsSelected.value = false
      isAllRowsSelected.value = true
      Vue.nextTick(() => {
        isSomeRowsSelected.value = false
      })
    } else if (intermediate) {
      isSomeRowsSelected.value = true
      isAllRowsSelected.value = false

      Vue.nextTick(() => {
        isSomeRowsSelected.value = true
      })
    } else {
      isAllRowsSelected.value = false
      isSomeRowsSelected.value = false
    }
  }

  const syncRowsSelection = (checked, slotData) => {
    if (checked) {
      slotData.selectAllRows()
    } else {
      slotData.clearSelected()
    }
  }

  const syncRowSelection = (checked, row) => {
    if (checked) {
      row.selectRow()
    } else {
      row.unselectRow()
    }
  }

  const onRowSelected = items => {
    rowsSelected.value = items
  }

  watch([rowsSelected], () => {
    syncSelectAllCheckbox()
  })

  return {
    rowsSelected,
    isAllRowsSelected,
    isSomeRowsSelected,

    syncRowsSelection,
    syncRowSelection,
    syncSelectAllCheckbox,
    onRowSelected,
  }
}

export const _ = null
