<template>

  <div>
    <!-- Filters -->
    <filters
      :customer-filter.sync="customerFilter"
      :status-filter.sync="statusFilter"
    />

    <!-- Table Container Card -->
    <model-list-table
      :store-module-name="storeModuleName"
      :table-columns="tableColumns"
      :filters-list="filtersList"
      :query-params="getQueryParams"
      :add-action="true"
      :view-action="true"
      :edit-action="false"
      :delete-action="true"
      :extra-actions="[]"
      :is-sortable="false"
    />
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from 'vue'
import { formatDatetime, priceText } from '@core/utils/filter'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import Filters from './Filters.vue'
import orderStoreModule from '../orderStoreModule'

export default {
  components: {
    Filters,
    ModelListTable,
  },
  directives: {
  },

  methods: {
  },
  setup() {
    const STORE_MODULE_NAME = 'orders'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const customerFilter = ref(null)
    const statusFilter = ref(null)

    const tableColumns = [
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      { key: 'id', sortable: false, label: 'ID' },
      {
        key: 'related_customer', sortable: true, label: 'modules.customers.s_c',
      },
      {
        key: 'total', sortable: true, label: 'Order Total', formatter: priceText,
      },
      { key: 'order_status', sortable: true, label: 'Status' },
      {
        key: 'paid_at',
        label: 'Payment Date',
        formatter: formatDatetime,
        sortable: true,
      },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: formatDatetime,
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: formatDatetime,
        sortable: true,
      },
      { key: 'actions' },
    ]

    const filtersList = [customerFilter, statusFilter]
    const getQueryParams = () => ({
      customer_id: customerFilter.value,
      status: statusFilter.value,
    })

    return {
      // Extra Filters
      customerFilter,
      statusFilter,

      // Table props
      tableColumns,
      storeModuleName: STORE_MODULE_NAME,
      filtersList,
      getQueryParams,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">

$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

::v-deep .b-table {
  tbody {
    tr {
      //cursor: move;
    }
  }
}

.b-table {
  tbody {
    tr.ready-for-drag {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 $border-color;
      transition: all 0.2s;
    }
    tr {
      &:has(td .draggable-handle) {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
    }
  }
}

.btn-link:hover {
  color: #5d6064 !important;
}
</style>
