import Sortable from 'sortablejs'

const draggabletable = {
  bind(el, binding, vnode) {
    const table = el
    // eslint-disable-next-line no-underscore-dangle
    table._draggable = Sortable.create(table.querySelector('tbody'), {
      ...binding.value,
      onEnd: evt => {
        const { context } = vnode
        // console.log('END', self)
        // console.log('evt', evt)

        // eslint-disable-next-line no-underscore-dangle
        const tableObj = table.__vue__ // context.refListTable
        const items = tableObj.localItems
        const targetItem = items.splice(evt.oldIndex, 1)[0]
        items.splice(evt.newIndex, 0, targetItem)

        context.hasReorderedRows = true
      },
    })
  },
}

const draggabletags = {
  bind(el, binding, vnode) {
    const ul = el.querySelector('ul')
    ul._draggable = Sortable.create(ul, {
      ...binding.value,
      onEnd: evt => {
        // console.log(evt)
        const data = vnode.context
        const targetItem = data.tags.splice(evt.oldIndex, 1)[0]
        data.tags.splice(evt.newIndex, 0, targetItem)
      },
    })
  },
}

export { draggabletable, draggabletags }
