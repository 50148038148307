export default {
  name: 'customers',

  translatedAttributes: [
    'name',
    'description',
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    id: item.id,
    first_name: item.first_name,
    last_name: item.last_name,
    email: item.email,
    mobile_code: item.mobile_code,
    mobile: item.mobile,
    country_code: item.country_code?.value || item.country_code,
    is_active: item.is_active,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    name: data.name || (`${data.first_name} ${data.last_name}`),
    email: data.email,
    mobile_code: data.mobile_code,
    mobile: data.mobile,
    formatted_mobile: data.formatted_mobile,
    country_code: data.country_code,
    created_at: data.created_at,
    is_active: !!data.is_active,
    is_email_verified: !!data.email_verified_at,
    is_mobile_verified: !!data.mobile_verified_at,
    orders_count: data.orders_count,
    orders_total: data.orders_total,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    first_name: errors.first_name,
    last_name: errors.last_name,
    email: errors.email,
    mobile: errors.mobile || errors.mobile_code,
    country_code: errors.country_code,
    is_active: errors.is_active,
  }),
}
