import { ref } from 'vue'
import store from '@/store'
import { catchException, toast } from '@core/utils/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export const useSortableTable = (storeModuleName, table, callback) => {
  const hasReorderedRows = ref(false)
  const { t } = useI18nUtils()

  function calcDisplayOrders(total, perPage, currentPage) {
    const result = []
    const startIndex = (currentPage - 1) * perPage
    let endIndex = startIndex + perPage
    if (endIndex >= total) {
      endIndex = total
    }

    let i
    for (i = startIndex; i < endIndex; i += 1) {
      result.push(total - i)
    }

    return result
  }

  const saveRowsSorting = (total, perPage, currentPage) => {
    // const table = table.value
    const displayOrders = calcDisplayOrders(total, perPage, currentPage)
    const postData = table.value.localItems.map((item, idx) => ({
      id: item.id,
      display_order: displayOrders[idx],
    }))

    table.value.localBusy = true
    store
      .dispatch(`${storeModuleName}/sorting`, { items: postData })
      .then(() => {
        toast('success', t('message.operation_success'))
        callback()
      })
      .catch(e => {
        catchException(e)
        // TODO: Localization
        toast('danger', t('message.operation_failed'), '', 'AlertTriangleIcon')
      })
      .finally(() => {
        // isTableBusy.value = false
        table.value.localBusy = false
      })
  }

  return {
    hasReorderedRows,
    saveRowsSorting,
  }
}

export const _ = null
