<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5 v-t="`Filters`" />
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label v-t="`inputs.customer`" />
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="customerFilter"
            :options="customerOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:customerFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label v-t="`inputs.status`" />
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="orderStatusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from 'vue'
import store from '@/store'
import customerStoreModule from '@/views/models/customers/customerStoreModule'
import { orderStatusOptions } from '@core/utils/constants'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    customerFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'customers'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const customerOptions = ref([])

    store.dispatch('customers/fetchList')
      .then(response => { customerOptions.value = response.data.data })

    return {
      customerOptions,
      orderStatusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
