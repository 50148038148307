import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import customerModel from './customerModel'
import countryModel from '../common/models/countryModel'

const {
  fetchList,
  fetchAllChildren,
  fetchAll,
  fetchOne,
  create,
  update,
  deleteMany,
  sorting,
} = useModelStoreModule(customerModel)

const {
  fetchList: fetchCountries,
} = useModelStoreModule(countryModel)

const fetchAddresses = (ctx, id) => fetchAllChildren(ctx, id, 'addresses')

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    create,
    update,
    deleteMany,
    sorting,

    fetchCountries,
    fetchAddresses,
  },
}
