import Vue, { ref, watch, computed } from 'vue'
import { debouncedWatch } from '@vueuse/core'

// Notification
import { useSortableTable } from '@core/comp-functions/tables/sortable'
import { useSelectableTable } from '@core/comp-functions/tables/selectable'
import store from '@/store'
import { catchException, toast } from '@core/utils/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {t} from "@core/libs/i18n/utils";

export default function useModelList(
  storeModuleName,
  tableColumns,
  filters = [],
  getQueryParams = () => {},
  onSortingSuccess = () => {},
) {
  // Use toast
  const { t } = useI18nUtils()
  // const t = string => (string) // TODO: Find solution for translations

  const refListTable = ref(null)

  const isTableBusy = ref(false)

  // Table Handlers
  const perPage = ref(5)
  const totalItems = ref(0)
  const serverMetaData = ref({})
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref(null)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      ...serverMetaData.value,
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const {
    rowsSelected,
    isAllRowsSelected,
    isSomeRowsSelected,
    syncRowsSelection,
    syncRowSelection,
    syncSelectAllCheckbox,
    onRowSelected,
  } = useSelectableTable(refListTable)

  const refreshData = () => {
    refListTable.value.refresh()
    refListTable.value.clearSelected()
    rowsSelected.value = []
    // eslint-disable-next-line no-use-before-define
    hasReorderedRows.value = false
  }

  const fetchItems = (ctx, callback) => {
    isTableBusy.value = true
    store
      .dispatch(`${storeModuleName}/fetchAll`, {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        ...getQueryParams(),
      })
      .then(response => {
        const responseData = response.data
        const items = responseData.data
        const { total } = responseData.meta

        callback(items)
        totalItems.value = total
        serverMetaData.value = responseData.meta
      })
      .catch(e => {
        catchException(e)
        toast(
          'danger',
          t('message.error_fetching_list'),
          null,
          'AlertTriangleIcon',
        )
      })
      .finally(() => {
        isTableBusy.value = false
      })
  }

  const performDelete = (items, success, failed = null) => {
    // isTableBusy.value = true

    store
      .dispatch(`${storeModuleName}/deleteMany`, items)
      .then(response => {
        if (response.status === 204) {
          success()
          refreshData()
        } else {
          toast(
            'danger',
            t('message.operation_failed'),
            response.data,
            'AlertTriangleIcon',
          )
        }
      })
      .catch(e => {
        catchException(e)
        toast(
          'danger',
          t('message.operation_failed'),
          null,
          'AlertTriangleIcon',
        )
      })
      .finally(() => {
        // isTableBusy.value = false
      })
  }

  const deleteItems = (event, item = null) => {
    const selectedItems = item ? [item] : rowsSelected.value
    const ids = []
    selectedItems.forEach((obj, i) => {
      ids[i] = obj.id
    })
    Vue.swal({
      title: t('Are you sure?'),
      text: t('You won\'t be able to revert this!'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: t('Cancel'),
      confirmButtonText: `${t('Yes, delete')} ${ids.length > 1
        ? `${ids.length} ${t('items')}!`
        : `${t('this item')}!`}`,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        performDelete(ids, () => {
          Vue.swal({
            icon: 'success',
            title: t('Deleted!'),
            text: t('Item(s) has been deleted.'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
      }
    })
  }

  const sortingCallback = () => {
    refreshData()

    if (onSortingSuccess) {
      onSortingSuccess()
    }
  }
  const { hasReorderedRows, saveRowsSorting } = useSortableTable(storeModuleName, refListTable, sortingCallback)

  watch([currentPage, perPage, ...filters], () => {
    refreshData()
    syncSelectAllCheckbox()
  })
  debouncedWatch([searchQuery], () => {
    refreshData()
    syncSelectAllCheckbox()
  }, { debounce: 500 })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveOrderStatusVariant = status => {
    if (status === 'DRFT') return 'secondary'
    if (status === 'PEND') return 'warning'
    if (status === 'PAID') return 'warning'
    if (status === 'PRCS') return 'warning'
    if (status === 'RDYS') return 'info'
    if (status === 'SHPD') return 'warning'
    if (status === 'RDYP') return 'info'
    if (status === 'DLVR') return 'success'
    if (status === 'RFSD') return 'danger'
    if (status === 'CNCL') return 'danger'
    if (status === 'RTRN') return 'secondary'
    return 'primary'
  }

  const resolvePurchaseStatusVariant = status => {
    if (status === 'DRFT') return 'secondary'
    if (status === 'PEND') return 'warning'
    if (status === 'PAID') return 'warning'
    if (status === 'PRCS') return 'warning'
    if (status === 'RDY') return 'info'
    if (status === 'RFSD') return 'danger'
    if (status === 'CNCL') return 'danger'
    if (status === 'RTRN') return 'secondary'
    if (status === 'DONE') return 'success'
    return 'primary'
  }

  const resolveCommissionStatusVariant = status => {
    if (status === 'pending') return 'secondary'
    if (status === 'accepted') return 'success'
    if (status === 'refused') return 'danger'
    return 'primary'
  }

  const resolveBooleanStatusVariant = status => (status ? 'success' : 'secondary')

  const resolveBooleanImportantStatusVariant = status => (status ? 'success' : 'danger')

  return {
    fetchItems,
    deleteItems,
    saveRowsSorting,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    rowsSelected,
    isAllRowsSelected,
    isSomeRowsSelected,
    isTableBusy,
    hasReorderedRows,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveOrderStatusVariant,
    resolvePurchaseStatusVariant,
    resolveCommissionStatusVariant,
    resolveBooleanStatusVariant,
    resolveBooleanImportantStatusVariant,
    refreshData,
    syncRowsSelection,
    syncRowSelection,
    onRowSelected,
  }
}
